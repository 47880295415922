import React from "react"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  },
}))

const TextInput = props => {
  const {
    id,
    label,
    value,
    onChange,
    type,
    onBlur,
    multiline,
    rows,
    rowsMax,
  } = props

  const classes = useStyles()

  const handleChange = event => {
    onChange(event.target.value)
  }

  return (
    <TextField
      className={classes.root}
      type={type}
      autoComplete="off"
      id={id}
      label={label}
      value={value}
      onChange={handleChange}
      onBlur={evt => onBlur(evt)}
      variant="outlined"
      multiline={multiline}
      rows={rows}
      rowsMax={rowsMax}
    />
  )
}

export default TextInput
