

import * as Card from "../../toolkit/Card.bs.js";
import * as Head from "../../components/head/Head.bs.js";
import * as I18n from "../../i18n/I18n.bs.js";
import * as $$Text from "../../toolkit/Text.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Stack from "../../toolkit/Stack.bs.js";
import * as React from "react";
import * as Button from "../../toolkit/Button.bs.js";
import * as Layout from "../../layout/Layout.bs.js";
import * as Belt_Int from "bs-platform/lib/es6/belt_Int.js";
import * as TextInput from "../../components/mui/TextInput.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Random$BsFaker from "bs-faker/src/Random.bs.js";
import * as LimitedWidthContainer from "../../toolkit/LimitedWidthContainer.bs.js";

function genNumbers(min, max) {
  return Belt_Array.map(new Array(10), (function (param) {
                return String(Random$BsFaker.number(min, max, undefined, undefined));
              }));
}

function NumbersPage$Generator(Props) {
  var generateButtonText = I18n.t("numbers.body.generate_button_text");
  var result = I18n.t("numbers.body.result");
  var rangeText = I18n.t("numbers.body.range");
  var minText = I18n.t("numbers.body.min");
  var maxText = I18n.t("numbers.body.max");
  var seoText = I18n.t("numbers.seo_text");
  var match = React.useState((function () {
          
        }));
  var setNumbers = match[1];
  var numbers = match[0];
  var match$1 = React.useState((function () {
          return "1";
        }));
  var setRangeStart = match$1[1];
  var rangeStart = match$1[0];
  var match$2 = React.useState((function () {
          return "100";
        }));
  var setRangeEnd = match$2[1];
  var rangeEnd = match$2[0];
  var rangeStartInt = Belt_Option.getWithDefault(Belt_Int.fromString(rangeStart), 1);
  var rangeEndInt = Belt_Option.getWithDefault(Belt_Int.fromString(rangeEnd), 100);
  var generate = function (param) {
    var numbers = genNumbers(rangeStartInt, rangeEndInt);
    return Curry._1(setNumbers, (function (param) {
                  return numbers;
                }));
  };
  var onClickRandomize = function ($$event) {
    $$event.preventDefault();
    return generate(undefined);
  };
  React.useEffect((function () {
          generate(undefined);
          
        }), ([]));
  return React.createElement(LimitedWidthContainer.make, {
              maxWidth: /* Px840 */2,
              fullWidthMobile: true,
              children: React.createElement(Stack.make, {
                    gap: /* Lg */3,
                    children: null
                  }, React.createElement(Card.make, {
                        children: null
                      }, React.createElement("p", undefined, rangeText), React.createElement(TextInput.make, {
                            type: "number",
                            id: "rangeStart",
                            label: minText,
                            value: rangeStart,
                            onChange: (function (v) {
                                return Curry._1(setRangeStart, (function (param) {
                                              return v;
                                            }));
                              }),
                            onBlur: (function (_evt) {
                                if (rangeStart === "") {
                                  return Curry._1(setRangeStart, (function (param) {
                                                return "1";
                                              }));
                                } else if (rangeStartInt > rangeEndInt) {
                                  return Curry._1(setRangeStart, (function (param) {
                                                return "1";
                                              }));
                                } else {
                                  return ;
                                }
                              }),
                            multiline: false
                          }), React.createElement(TextInput.make, {
                            type: "number",
                            id: "rangeEnd",
                            label: maxText,
                            value: rangeEnd,
                            onChange: (function (v) {
                                return Curry._1(setRangeEnd, (function (param) {
                                              return v;
                                            }));
                              }),
                            onBlur: (function (_evt) {
                                if (rangeEnd === "") {
                                  return Curry._1(setRangeEnd, (function (param) {
                                                return "100";
                                              }));
                                } else if (rangeStartInt > rangeEndInt) {
                                  return Curry._1(setRangeEnd, (function (param) {
                                                return "100";
                                              }));
                                } else {
                                  return ;
                                }
                              }),
                            multiline: false
                          })), React.createElement(Button.make, {
                        label: generateButtonText,
                        onClick: onClickRandomize
                      }), React.createElement(Card.make, {
                        children: null
                      }, React.createElement("h5", undefined, result), numbers !== undefined ? React.createElement("ul", undefined, Belt_Array.mapWithIndex(numbers, (function (i, number) {
                                    return React.createElement("li", {
                                                key: String(i)
                                              }, number);
                                  }))) : null), React.createElement("div", undefined, React.createElement("p", undefined, seoText)))
            });
}

var Generator = {
  Faker: undefined,
  genNumbers: genNumbers,
  make: NumbersPage$Generator
};

function NumbersPage(Props) {
  var pageContext = Props.pageContext;
  var locale = Belt_Option.getWithDefault(pageContext.locale, "en");
  I18n.changeLanguage(locale);
  var title = I18n.t("numbers.head.title");
  var description = I18n.t("numbers.head.description");
  var h1Text = I18n.t("numbers.body.h1");
  var h2Text = I18n.t("numbers.body.h2");
  return React.createElement(React.Fragment, undefined, React.createElement(Head.make, {
                  title: title,
                  description: description,
                  lang: locale,
                  route: /* Numbers */2
                }), React.createElement(Layout.make, {
                  route: /* Numbers */2,
                  locale: locale,
                  children: null
                }, React.createElement($$Text.H1.make, {
                      align: /* center */98248149,
                      children: h1Text
                    }), React.createElement($$Text.H2.make, {
                      align: /* center */98248149,
                      children: h2Text
                    }), React.createElement(NumbersPage$Generator, { })));
}

var route = /* Numbers */2;

var make = NumbersPage;

var $$default = NumbersPage;

export {
  route ,
  Generator ,
  make ,
  $$default ,
  $$default as default,
  
}
/* Card Not a pure module */
