

import * as TextInputJs from "../../../components/textInput.js";

var make = TextInputJs.default;

export {
  make ,
  
}
/* make Not a pure module */
